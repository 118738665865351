import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useFragment } from '@apollo/client'

import { bakeryPath } from 'shared/utils/bakeries'
import { CategoryFragment } from 'web/graphql/fragments'
import { useBakeryContext } from 'web/contexts/BakeryContext'
import { useThemeContext } from 'web/contexts/ThemeContext'

export const CategoryMenuLink = React.memo(({ slug }) => {
  const { bakery } = useBakeryContext()
  const { primaryColor } = useThemeContext()

  const { data } = useFragment({
    fragment: CategoryFragment,
    fragmentName: 'Category',
    from: {
      __typename: 'Category',
      slug,
    },
  })

  const customSections = useMemo(
    () =>
      bakery.customSections.filter((section) =>
        data.default
          ? !section.category && section.kind === 'itemized' && section.choices.length > 0
          : section.category?.slug === data.slug,
      ),
    [bakery.customSections, data],
  )

  const hasProducts = useMemo(() => data.offerings.length > 0, [data.offerings])
  const hasBakedGoods = useMemo(() => bakery.bakedGoods.length > 0, [bakery.bakedGoods])
  const hasCustomSections = useMemo(() => customSections.length > 0, [customSections])

  if (data.default && !hasProducts && !hasBakedGoods && !hasCustomSections) return null
  if (!data.default && !hasProducts && !hasCustomSections) return null

  return (
    <MenuLink $primaryColor={primaryColor} to={`${bakeryPath(bakery)}/category/${slug}`}>
      {data.name}
    </MenuLink>
  )
})

export const MenuLink = styled(Link)(
  ({ $primaryColor }) => `
    text-align: center;
    z-index: 1001;
    :hover {
      color: ${$primaryColor};
    }
  `,
)
