import { useCallback, useMemo } from 'react'

import { insertKeyIf } from 'shared/utils/insertIfFunctions'

/**
 * This hook is used to building Custom Questions to display for the Order inside Questionnaire screen/webpage
 *
 * @param currentPage - string, name of current page, only applicable for website
 * @param orderItems - array of Order's order items to attach questions to/for. [{ offeringId: String, categoryId?: String, name: String }]
 * @param customQuestions - array of Custom Questions that came from Bakery (e.g. bakery.customQuestions)
 * @param withNames - boolean indicating whether to add the Order items name to the Question. Used only inside the app
 */

export const useOrderQuestionsBuilder = ({
  currentPage,
  orderItems,
  customQuestions = [],
  withNames = false,
}) => {
  const [orderOfferingIds, orderCategoryIds] = useMemo(() => {
    const offeringIds = []
    const categoryIds = []

    orderItems.forEach((oi) => {
      offeringIds.push(oi.offeringId)
      categoryIds.push(oi.categoryId)
    })
    return [new Set(offeringIds), new Set(categoryIds)]
  }, [orderItems])

  const forOfferings = useCallback(
    (offerings) => {
      if (!offerings.length) return

      const offeringIds = offerings.map((o) => o.id)
      return offeringIds.some((id) => orderOfferingIds.has(id))
    },
    [orderOfferingIds],
  )

  const forCategories = useCallback(
    (categories) => {
      if (!categories.length) return

      const categoryIds = categories.map((c) => c.id)
      return categoryIds.some((id) => orderCategoryIds.has(id))
    },
    [orderCategoryIds],
  )

  return useMemo(() => {
    if (!customQuestions?.length || currentPage === 'details') return []

    return customQuestions.reduce((acc, cq) => {
      const withOfferings = forOfferings(cq.offerings) || forCategories(cq.categories)

      // If custom question has no categories and offerings or has offerings but not applied separately
      // it means that either it's a general question or that it's for other category/offering not in the Order
      if (
        ((cq.offerings.length === 0 && cq.categories.length === 0) || withOfferings) &&
        !cq.applySeparately
      ) {
        acc.push(cq)
      }

      if (withOfferings && cq.applySeparately) {
        // here we're building array of order items that we need to show questions for
        const questionOrderItems = orderItems.reduce((oiAcc, oi) => {
          if (cq.categories.some((cc) => cc.id === oi.categoryId)) oiAcc.push(oi)
          if (cq.offerings.some((co) => co.id === oi.offeringId)) oiAcc.push(oi)
          return oiAcc
        }, [])

        questionOrderItems.forEach((qoi, idx) =>
          acc.push({
            ...cq,
            orderItemIdx: idx,
            forId: qoi.offeringId,
            ...insertKeyIf(withNames, { question: `${cq.question} (${qoi.name})` }),
          }),
        )
      }

      return acc
    }, [])
  }, [customQuestions, currentPage, forOfferings, forCategories, orderItems, withNames])
}
