export const StorageKeys = {
  refreshTokenStorageKey: 'bakesy:refreshToken',
  appIntroCompleteKey: 'bakesy:appIntroCompleteKey',
  beforeYouStartKey: 'bakesy:beforeYouStart:2',
  jwtStorageKey: 'bakesy:jwtStorageKey',
  notificationsSkipped: 'bakesy:notificationsSkipped',
  notificationsSystemPrompted: 'bakesy:notificationsSystemPrompted',
  lastVersionPromptedForReview: 'bakesy:lastVersionPromptedForReview',
  calendarEvents: 'bakesy:calendarEvents',
  completionDate: 'bakesy:completionDate',
}

export const weekdays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

export const initialRatingMessage =
  'Hi! Would you please leave me a rating or review for your completed order:'
