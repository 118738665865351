import AriaModal from 'react-aria-modal'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { closeAuthModal, openCreateAccount } from 'shared/reducers/ui'
import { Colors } from 'shared/styles/Colors'
import { CreateAccount } from './CreateAccount'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { login } from 'shared/reducers/account'
import { MediaQueries } from 'web/styles/responsive'
import { notifyAboutManagement } from 'shared/utils/bakeries'
import { SignIn } from './SignIn'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '@apollo/client'
import { VerifyServiceAccountDocument } from 'shared/graphql/mutations'

export const AuthModal = React.memo(() => {
  const dispatch = useDispatch()
  const authModalState = useSelector((state) => state.ui.authModalState)
  const [verifyServiceAccountMutation] = useMutation(VerifyServiceAccountDocument)
  const [serviceData, setServiceData] = useState({})

  const receiveMessage = useCallback(
    async (event) => {
      let parsedEvent

      if (typeof event.data === 'string' && event.data) {
        try {
          const { data } = JSON.parse(event.data)
          parsedEvent = { data: { provider: 'apple', token: data?.authorization?.id_token } }
        } catch (error) {
          console.error(error)
        }
      } else {
        parsedEvent = event
      }

      if (!parsedEvent?.data?.provider || !parsedEvent?.data?.token) return

      console.log('received verify service account event', parsedEvent)

      try {
        const {
          data: { verifyServiceAccount, errors },
        } = await verifyServiceAccountMutation({ variables: { input: parsedEvent.data } })

        if (!errors && verifyServiceAccount.jwt) {
          const { user, jwt, refreshToken } = verifyServiceAccount
          dispatch(login({ user, jwt, refreshToken }))
          notifyAboutManagement({ user, dispatch })
          dispatch(closeAuthModal())
        } else if (!errors && verifyServiceAccount.serviceData) {
          setServiceData(verifyServiceAccount.serviceData)
          dispatch(openCreateAccount())
        }
      } catch (e) {}
    },
    [dispatch, verifyServiceAccountMutation],
  )

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false)
    return () => window.removeEventListener('message', receiveMessage)
  }, [receiveMessage])

  const closeModal = () => {
    setServiceData({})
    dispatch(closeAuthModal())
  }

  return authModalState !== 'closed' ? (
    <AriaModal
      titleText="Authentication"
      underlayStyle={{ paddingTop: '40px', paddingBottom: '40px' }}
      underlayClickExits={false}
      escapeExits={false}
      verticallyCenter
      focusDialog={authModalState === 'create-account'}
    >
      <Modal>
        <CloseContainer onClick={closeModal}>
          <FontAwesomeIcon icon={faTimes} color={Colors.grey50} />
        </CloseContainer>
        {authModalState === 'sign-in' && <SignIn />}
        {authModalState === 'create-account' && (
          <CreateAccount serviceData={serviceData} setServiceData={setServiceData} />
        )}
      </Modal>
    </AriaModal>
  ) : null
})

const Modal = styled.div`
  background-color: ${Colors.white};
  padding: 30px;
  border-radius: 4px;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  width: calc(100% - 40px);
  position: relative;
  margin: 0 20px;

  @media ${MediaQueries.mdUp} {
    width: 550px;
    margin: 0 auto;
  }
`

const CloseContainer = styled.div`
  padding: 15px;
  position: absolute;
  top: 0px;
  right: 2px;
  cursor: pointer;
`
