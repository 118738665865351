import React, { memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { faCopy } from '@fortawesome/pro-regular-svg-icons'

import { Button } from 'web/components/Button'
import { Colors } from 'shared/styles/Colors'
import { Icon } from 'shared/components/Icon/Icon'
import { MediaQueries } from 'web/styles/responsive'

export const PaymentLink = memo(
  ({ methodName, link, isMail = false, copy = false, asLink = true }) => {
    const [copied, setCopied] = useState(false)

    useEffect(() => {
      if (copied) setTimeout(() => setCopied(false), 3000)
    }, [copied])

    const shouldRenderLink = link && asLink
    const shouldRenderMethod = (!link || !asLink) && methodName
    const hasCopyButton = shouldRenderMethod && link

    const handleCopy = () => {
      setCopied(true)
      navigator.clipboard.writeText(link)
    }

    const renderLabel = () => (
      <MethodName>
        {methodName}
        {link && ':'}
      </MethodName>
    )

    const renderLink = () => (
      <Link href={isMail ? `mailto:${link}` : link} copy={copy}>
        {link}
      </Link>
    )

    const renderMethod = () => <Method copy={copy}>{link}</Method>

    const renderCopyButton = () => (
      <CopyButton type="button" onClick={handleCopy}>
        <Icon icon={copied ? faCheck : faCopy} size={25} />
      </CopyButton>
    )

    return (
      <Container>
        {renderLabel()}

        <MethodContainer>
          {shouldRenderLink && renderLink()}
          {shouldRenderMethod && renderMethod()}
          {hasCopyButton && renderCopyButton()}
        </MethodContainer>
      </Container>
    )
  },
)

const overflowStyles = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 5,
  alignItems: 'center',
  justifyContent: 'space-between',

  [`@media ${MediaQueries.smDn}`]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderBottom: `1px solid ${Colors.grey25}`,
    padding: '5px 0',

    '&:first-child': {
      paddingTop: 0,
    },

    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: 0,
    },
  },
})

const Link = styled.a({
  ...overflowStyles,
  color: Colors.blue,
})

const Method = styled.div({
  ...overflowStyles,
})

const CopyButton = styled(Button)({
  width: 26,
  height: 26,
  backgroundColor: 'transparent',
  color: Colors.brand,
  border: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const MethodName = styled.div({
  ...overflowStyles,
  maxWidth: '100%',
  flexShrink: 0,
  flexGrow: 0,
  fontWeight: 600,

  [`@media ${MediaQueries.smUp}`]: {
    width: 'fit-content',
  },
})

const MethodContainer = styled.div({
  display: 'flex',
  gap: '5px',

  [`@media ${MediaQueries.smDn}`]: {
    width: '100%',
  },
})
