import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { formatISO, parseISO } from 'date-fns'

import { CalendarComponent } from 'web/components/Calendar'
import { getDay, getISODateOnly } from 'shared/utils/dates'
import { Header } from 'web/pages/OrderRequest/styles'
import { isEmptyShallow } from 'shared/utils/isEmpty'
import { OrderRequestLayout } from 'web/pages/OrderRequest/OrderRequestLayout'
import { PlatformStorage } from 'shared/services/PlatformStorage'
import { StorageKeys } from 'shared/constants/constants'
import { StyledTextArea } from 'web/components/form/TextAreaField'
import { useOfferingsAvailability } from 'shared/hooks/useOfferingsAvailability'
import { useOrderContext } from 'web/contexts/OrderContext'
import { useUpdateOrder } from 'shared/hooks/useUpdateOrder'

export const CompletionDate = () => {
  const { bakery, order } = useOrderContext()
  const { loading, updateOrder } = useUpdateOrder({ order })
  const presetCompletionDate =
    order.completionDate || PlatformStorage.getItem(StorageKeys.completionDate)

  const [completionDate, setCompletionDate] = useState(
    presetCompletionDate ? parseISO(presetCompletionDate) : null,
  )

  const offeringsAvailability = useOfferingsAvailability(order.orderItems)
  const isDateUnavailable = useCallback(
    (date) => {
      const restrictedItems = offeringsAvailability?.byOrderItem
      const restrictedItemsKeys = Object.keys(restrictedItems || {})

      if (isEmptyShallow(restrictedItemsKeys)) return false

      const day = getDay(date)
      const isoDate = getISODateOnly(date)

      return !restrictedItemsKeys.every(
        (ri) => restrictedItems[ri].includes(day) || restrictedItems[ri].includes(isoDate),
      )
    },
    [offeringsAvailability],
  )

  const nextDisabled = !completionDate || loading || isDateUnavailable(completionDate)

  const onNext = ({ navigateToNextScreen }) => {
    const formattedDate = formatISO(completionDate, { representation: 'date' })

    updateOrder({ input: { completionDate: formattedDate } }, navigateToNextScreen)
    PlatformStorage.removeItem(StorageKeys.completionDate)
  }

  return (
    <OrderRequestLayout nextDisabled={nextDisabled} onNext={onNext}>
      <StyledHeader>When do you need this order?</StyledHeader>
      <CalendarComponent
        value={completionDate}
        onChange={setCompletionDate}
        bakery={bakery}
        offeringsAvailability={offeringsAvailability}
        isDateUnavailable={isDateUnavailable}
      />
    </OrderRequestLayout>
  )
}

export const StyledInput = styled(StyledTextArea)({
  flex: 1,
})

export const StyledHeader = styled(Header)({})
