import { Platform } from './Platform'

let platform
if (typeof document !== 'undefined') {
  platform = 'web'
} else if (typeof navigator !== 'undefined' && navigator.product === 'ReactNative') {
  platform = 'native'
} else {
  platform = 'node'
}

const isWeb = platform === 'web'
const isNative = platform === 'native'

let ablyClientKey = 'gofGPA.EtNKHQ:Pd8LSQwlmJZ06sYn'

let googleOAuthClientId = '714598151604-1v4c3ogfg6cpkq10fc6sn7tq1k56hb3f.apps.googleusercontent.com'
const facebookOAuthClientId = '893792991063258'

let apiUrl
const environment = Platform.environment
let webOAuthRedirectUri
let bakesyUrl
let appleOAuthClientId = 'com.ryanboland.bakesy.debug.sign-in'
let stripeToken = 'pk_test_QsaL0nW2QYHGhOpyvv5xCUji00TmXgifkZ'
let stripeOAuthClientId = 'ca_Gz3odgaxeZbcaCVcS9vjHHfgAdffwReZ'
let bugsnagWebKey = '1922aeae728b0d30265b3208bc112a9f'
let revenuecatKey
if (Platform.isAndroid) revenuecatKey = 'goog_hcLtrimChQobfRmQMQXFrnPijqU'
else revenuecatKey = 'appl_WXWbofGPiNqYAPWMoHHpcghdFnZ'

if (environment === 'production') {
  apiUrl = 'https://api.bakesy.app'
  bakesyUrl = 'https://bakesy.shop'
  webOAuthRedirectUri = 'https://shop.bakesy.app/oauth-redirect'
  appleOAuthClientId = 'app.bakesy.release.sign-in'
  stripeToken = 'pk_live_IhLoq5NparZsR77tjOTlhVGG00PBtLxBBS'
  stripeOAuthClientId = 'ca_Gz3o5LF2gtSEvak17IP3dnjSbrWU3H4w'
  revenuecatKey = 'aVccVXakXmdmTOtKTumBjkTNjWtQXkzN'
} else if (environment === 'staging') {
  apiUrl = 'https://api-staging.bakesy.app'
  bakesyUrl = 'https://staging.bakesy.app'
  revenuecatKey = 'MFBynJKFRZcDvzyHFfzyWMQIOXaAmseO'
  webOAuthRedirectUri = 'https://staging.bakesy.app/oauth-redirect'
} else {
  let apiHostname
  if (Platform.isAndroid && Platform.isSimulator) apiHostname = '10.0.2.2'
  else apiHostname = '192.168.0.31'
  apiUrl = `http://${apiHostname}:3000`
  bakesyUrl = `http://${apiHostname}:3001`
  webOAuthRedirectUri = 'http://localhost:3001/oauth-redirect'
}

let facebookOAuthRedirectUri = webOAuthRedirectUri
let googleOAuthRedirectUri = webOAuthRedirectUri
let appleOAuthRedirectUri = webOAuthRedirectUri

if (isNative) {
  let googleId

  if (environment === 'production') {
    // Release
    if (Platform.isAndroid) googleId = '714598151604-9q2nkrts7qfj5ctlsfk4b15ehbuaou24'
    else googleId = '714598151604-6c70turqvnceepu9he55m7eh1l0m37ec'
  } else if (environment === 'staging') {
    // Internal
    if (Platform.isAndroid) googleId = '714598151604-7bpheq8ti5qtmef25129hmf8pe0nsgn7'
    else googleId = '714598151604-bmjb762rj1jdi85l7o1at7nr9jeostrl'
  } else {
    // Debug
    if (Platform.isAndroid) googleId = '714598151604-fkovdq91haj9d69d5o8p0066vd4s2dbb'
    else googleId = '714598151604-okk0eo6a3qqvh4k5ljiahujo289gtfn7'
  }

  googleOAuthClientId = `${googleId}.apps.googleusercontent.com`
  googleOAuthRedirectUri = `com.googleusercontent.apps.${googleId}:/oauth2redirect/google`
}

const iosAppStoreId = '1515139393'
const appsFlyerDevKey = 'iqoUgH7q9e5SVNvJVWYrAf'

export const Configuration = {
  apiUrl,
  appsFlyerDevKey,
  graphqlUrl: `${apiUrl}/graphql`,
  directUploadUrl: `${apiUrl}/rails/active_storage/direct_uploads`,
  ablyClientKey,
  ...Platform,
  isWeb,
  isNative,
  googleOAuthClientId,
  googleOAuthEndpoint: 'https://accounts.google.com/o/oauth2/v2/auth',
  googleOAuthRedirectUri,
  facebookOAuthClientId,
  facebookOAuthEndpoint: 'https://www.facebook.com/v19.0/dialog/oauth',
  facebookOAuthRedirectUri,
  appleOAuthClientId,
  appleOAuthRedirectUri,
  bakesyUrl,
  stripeToken,
  stripeOAuthClientId,
  supportEmail: 'support@bakesy.app',
  termsUrl: 'https://www.bakesy.app/terms-of-service',
  faq: 'https://www.bakesy.app/faqs',
  getHelpUrl: 'https://www.bakesy.app/help',
  privacyUrl: 'https://www.bakesy.app/privacy-policy',
  bakerMarketingSiteUrl: 'https://www.bakesy.app',
  marketplaceComingSoonUrl: 'https://www.bakesy.app/shop',
  iosAppStoreId,
  iosAppStoreUrl: `https://apps.apple.com/us/app/id${iosAppStoreId}`,
  helpGuideUrl: 'https://www.bakesy.app/support',
  bugsnagWebKey,
  revenuecatKey,
  dateFormat: 'MMM dd, yyyy',
  emilyId:
    environment === 'production'
      ? '23f50d55-43b3-43ac-988d-17f3ee967718'
      : 'd4dcf80e-c4d5-42d8-b845-bc983cbbbece',
  bakesyTipsId:
    environment === 'production'
      ? '5814b6ca-789c-429c-a7f1-74e33e6e8ce3'
      : '11467bba-94b8-4624-bd97-dca4bc3e9183',
  bakesyAnnouncementsId:
    environment === 'production'
      ? '4241338b-b3fd-4740-b649-ec5d213d41b8'
      : '67a0aa29-fdba-4c25-8401-63b4321e38d5',
  bakesyRefundsId:
    environment === 'production'
      ? '6da4fb2c-90c5-403f-b318-ce4be2880db7'
      : 'ff55126f-e643-4aa4-a7ac-cd1e3f1f6a7c',
}
