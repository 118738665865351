import React, { useMemo } from 'react'
import styled from 'styled-components'

import { Helper, Row, Strong } from './styles'
import { useInvoiceContext } from 'shared/contexts/InvoiceContext'

export const CustomerSection = ({ show, Container = React.Fragment, printOrder }) => {
  const { order } = useInvoiceContext()
  const { customer } = order

  const name = useMemo(() => {
    return customer?.name ?? order.customerName
  }, [customer, order])

  const email = useMemo(() => {
    return customer?.email
  }, [customer])

  const phone = useMemo(() => {
    return customer?.mobileNumber
  }, [customer])

  if (!show) return null

  return (
    <Container>
      <Row $marginBottom={15}>
        <Strong>Customer</Strong>
      </Row>

      <Row>
        <Helper $printOrder={printOrder}>{name}</Helper>
      </Row>

      <Row>
        <Helper $printOrder={printOrder}>
          <Link href={`mailto:${email}`}>{email}</Link>
        </Helper>
      </Row>

      <Row>
        <Helper $printOrder={printOrder}>
          <Link href={`tel:${phone}`}>{phone}</Link>
        </Helper>
      </Row>
    </Container>
  )
}

const Link = styled.a({
  color: 'inherit',
})
