import { gql } from '@apollo/client'

const UserSettingsFragment = gql`
  fragment UserSettingsFragment on UserSettings {
    calendarSync
    emailMessages
    emailReminders
    pushNotificationsMessages
    pushNotificationsReminders
    textMessagesMessages
    textMessagesReminders
  }
`

const ChatImages = gql`
  fragment ChatImagesFragment on Message {
    images {
      id
      thumbnailUrl
      fullUrl
    }
  }
`

const ChatMessage = gql`
  fragment ChatMessageFragment on Message {
    id
    content
    sentAt
    read
    sender {
      id
      name
      verified
      avatar {
        id
        smallUrl
      }
      bakery {
        id
        name
        slug
        bakesyUrl
      }
    }
    receiver {
      id
      name
      verified
      avatar {
        id
        smallUrl
      }
      bakery {
        id
        name
        slug
        bakesyUrl
      }
    }
  }
`

const CustomSection = gql`
  fragment CustomSectionFragment on CustomSection {
    choices
    id
    kind
    text
    title
    category {
      id
      slug
    }
  }
`

export const Fragments = {
  ChatImages,
  ChatMessage,
  CustomSection,
  UserSettingsFragment,
}
