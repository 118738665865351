import * as Ably from 'ably'
import { createSelector } from 'reselect'
import { createSlice } from '@reduxjs/toolkit'
import { gql } from '@apollo/client'

import { apolloClient } from 'shared/services/graphqlClient'
import { Fragments } from 'shared/constants/Fragments'
import { Message } from 'shared/models/Message'
import { Platform } from 'shared/services/Platform'
import { refreshJwt } from './accountMutations'

let ably
let channel

const initialState = {
  messages: {},
  user: null,
  serviceAccounts: [],
  conversationsState: 'initial',
  drafts: {},
}

const messengerSlice = createSlice({
  name: 'messenger',
  initialState,
  reducers: {
    resetState: () => {
      return initialState
    },
    setConversationState: (state, action) => {
      state.conversationsState = action.payload
    },
    _setUser: (state, action) => {
      state.user = action.payload
    },
    addMessages: (state, action) => {
      const messages = action.payload
      messages.forEach((message) => messageAdder({ state, message }))
    },
    removeUserMessages: (state, action) => {
      delete state.messages[action.payload]
    },
    addMessage: (state, action) => {
      const message = action.payload
      messageAdder({ state, message })
    },
    _markRead: (state, action) => {
      const { otherUserId } = action.payload
      const messages = state.messages[otherUserId]

      if (!messages) return

      const newMessages = messages.map((m) => {
        const message = new Message(m).data
        message.read = true
        return message
      })

      return {
        ...state,
        messages: {
          ...state.messages,
          [otherUserId]: newMessages,
        },
      }
    },
    setServiceAccounts: (state, action) => {
      state.serviceAccounts = action.payload
    },
    addDraft: (state, action) => {
      state.drafts[action.payload.receiverId] = action.payload
    },
    removeDraft: (state, action) => {
      delete state.drafts[action.payload]
    },
  },
})

export const setUser = (user) => {
  return async (dispatch, getState) => {
    dispatch(_setUser(user))

    setTimeout(() => {
      dispatch(loadConversations())
    }, 50)

    ably = new Ably.Realtime({
      token: getState().account.jwt,
      authCallback: async (tokenParams, callback) => {
        if (Platform.environment !== 'development') {
          try {
            const jwt = await dispatch(refreshJwt())
            callback(null, jwt)
          } catch (e) {
            callback(e, null)
          }
        }
      },
    })

    ably.connection.on('connected', () => {
      console.log('ably connected')
    })

    ably.connection.on('failed', () => {
      console.log('ably failed connection')
    })

    channel = ably.channels.get(user.ablyChannelId)

    channel.subscribe('message', ({ data }) => {
      console.log('got ably message', data)
      const message = new Message(JSON.parse(data).message, user).data
      dispatch(addMessage(message))
    })

    channel.on((status) => {
      console.log('channel status', status)
    })
  }
}

export const clearUser = () => {
  return async (dispatch) => {
    if (ably) {
      ably.connection.off()
    }
    if (channel) {
      channel.unsubscribe()
      channel.off()
    }
    dispatch(resetState())
  }
}

export const loadConversations = ({ newConversation = null } = {}) => {
  return async (dispatch, getState) => {
    const { conversationsState, user } = getState().messenger

    if (conversationsState === 'loading') return
    dispatch(setConversationState('loading'))

    try {
      const { data } = await apolloClient.query({
        query: conversationsQuery,
      })

      dispatch(addMessages(data.conversations.results.map((m) => new Message(m, user).data)))
      if (newConversation) dispatch(addMessage(newConversation))
      dispatch(setConversationState('loaded'))
    } catch (e) {
      console.log(e)
      dispatch(setConversationState('error'))
    }
  }
}

export const loadMessages = ({ otherUserId }) => {
  return async (dispatch, getState) => {
    const { user } = getState().messenger
    const { data } = await apolloClient.query({ query: messagesQuery, variables: { otherUserId } })

    try {
      dispatch(addMessages(data.messages.results.map((m) => new Message(m, user).data)))
    } catch (error) {
      console.log(error)
      return { error }
    }
  }
}

export const markRead = ({ otherUserId }) => {
  return async (dispatch, getState) => {
    const messagesFor = messagesForSelector(getState())
    const activeMessages = messagesFor(otherUserId)
    if (activeMessages.every((m) => m.read)) return

    try {
      await apolloClient.mutate({
        mutation: markReadQuery,
        variables: { input: { otherUserId } },
      })
      dispatch(_markRead({ otherUserId }))
    } catch (e) {
      console.log('error marking as read', e)
    }
  }
}

export const sendMessage = (content, { receiverId, images = [] }) => {
  return async (dispatch, getState) => {
    const { user } = getState().messenger
    try {
      const { data } = await apolloClient.mutate({
        mutation: sendMessageMutation,
        variables: {
          input: { receiverId, content, images },
        },
      })
      dispatch(addMessage(new Message(data.sendMessage.message, user).data))
      return { data }
    } catch (error) {
      console.log('error sending message', error)
      return { error }
    }
  }
}

export const loadServiceAccounts = () => {
  return async (dispatch) => {
    const { data } = await apolloClient.query({
      query: serviceAccountsQuery,
    })

    dispatch(setServiceAccounts(data.serviceAccounts))
  }
}

const {
  _markRead,
  _setUser,
  setConversationState,
  addMessage,
  addMessages,
  resetState,
  setServiceAccounts,
} = messengerSlice.actions

export const { removeUserMessages, addDraft, removeDraft } = messengerSlice.actions
export const messengerReducer = messengerSlice.reducer

const userSelector = (state) => state.messenger.user

export const conversationsSelector = createSelector(
  (state) => state.messenger.messages,
  (messages) => {
    const conversations = Object.keys(messages).map((k) => {
      return messages[k][0]
    })
    conversations.sort((a, b) => {
      return (
        (b.newConversation ? 1 : 0) - (a.newConversation ? 1 : 0) ||
        new Date(b.sentAt) - new Date(a.sentAt)
      )
    })
    return conversations
  },
)

export const hasUnreadSelector = createSelector(
  userSelector,
  conversationsSelector,
  (user, conversations) => conversations.some((c) => user?.id !== c?.sender?.id && !c?.read),
)

export const messagesForSelector = createSelector(
  (state) => state.messenger.messages,
  (messages) => {
    return (otherUserId) =>
      messages[otherUserId] ? messages[otherUserId].filter((m) => !m.newConversation) : []
  },
)

const messageAdder = ({ state, message }) => {
  const other = message.otherUser

  if (state.messages[other.id]) {
    if (message.newConversation) return
    const messages = state.messages[other.id]

    const newConvoIndex = messages.findIndex((m) => m.newConversation)
    if (newConvoIndex > -1) messages.splice(newConvoIndex, 1)
    const existingConvoIndex = messages.findIndex((m) => m.id === message.id)

    if (existingConvoIndex === -1) {
      messages.unshift(message)

      if (messages.length > 1) {
        state.messages[other.id] = messages
          .slice()
          .sort((a, b) => new Date(b.sentAt) - new Date(a.sentAt))
      }
    } else {
      // Replace existing state entry as images may have been downloaded
      // Images aren't downloaded when requesting the conversation list, for efficiency
      messages[existingConvoIndex] = message
      state.messages[other.id] = messages
    }
  } else {
    if (message.newConversation) {
      Object.keys(state.messages).forEach((k) => {
        if (state.messages[k][0].newConversation) {
          delete state.messages[k]
        }
      })
    }
    state.messages[other.id] = [message]
  }
}

const conversationsQuery = gql`
  ${Fragments.ChatMessage}

  query getConversations {
    conversations {
      results {
        ...ChatMessageFragment
      }
    }
  }
`

const sendMessageMutation = gql`
  ${Fragments.ChatImages}
  ${Fragments.ChatMessage}

  mutation SendMessage($input: SendMessageInput!) {
    sendMessage(input: $input) {
      message {
        ...ChatMessageFragment
        ...ChatImagesFragment
      }
    }
  }
`

const messagesQuery = gql`
  ${Fragments.ChatImages}
  ${Fragments.ChatMessage}

  query getMessages($otherUserId: ID!) {
    messages(otherUserId: $otherUserId) {
      results {
        ...ChatMessageFragment
        ...ChatImagesFragment
      }
    }
  }
`

const markReadQuery = gql`
  mutation markRead($input: MarkReadInput!) {
    markRead(input: $input) {
      errors
    }
  }
`
const serviceAccountsQuery = gql`
  query GetServiceAccounts {
    serviceAccounts {
      id
      name
      verified
      avatar {
        smallUrl
      }
    }
  }
`
