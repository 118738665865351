import {
  createFragmentRegistry,
  defaultDataIdFromObject,
  InMemoryCache,
} from '@apollo/client/cache'

import * as fragments from 'web/graphql/fragments'
import * as sharedFragments from 'shared/graphql/fragments'

const fragmentRegistry = createFragmentRegistry(
  ...Object.values(fragments),
  ...Object.values(sharedFragments),
)

export const cache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case 'Offering':
        /**
         * For offerings we do not always have a slug, so if we do not
         * have one we should use the default data id from object.
         **/
        if (responseObject.slug) return `Offering:${responseObject.slug}`
        else return defaultDataIdFromObject(responseObject)

      case 'CustomAnswer':
        /**
         * When we have several answers for the same questions i.e. question for category
         * it will duplicate the answers, so we need to differentiate them
         **/
        if (responseObject.id && responseObject.forId)
          return `CustomAnswer:${responseObject.id + responseObject.forId}`
        else return defaultDataIdFromObject(responseObject)

      case 'AvailabilityPreferences':
        // There shouldn't be availability preferences without offering
        return `AvailabilityPreferences:${responseObject.offeringSlug}`

      default:
        return defaultDataIdFromObject(responseObject)
    }
  },
  fragments: fragmentRegistry,
  typePolicies: {
    Category: {
      keyFields: ['slug'],
    },
  },
})
