import { format, parseISO } from 'date-fns'

export const formatISODate = (date, { twoDigitYear = false } = {}) => {
  if (!date) return null

  let yearType = 'numeric'
  if (twoDigitYear) yearType = '2-digit'

  const parsedDate = parseISO(date)
  return parsedDate.toLocaleDateString(undefined, {
    month: 'numeric',
    day: 'numeric',
    year: yearType,
  })
}

export const formatDate = (date) => format(parseISO(date), 'MMMM d, yyyy')

const removePrecedingZero = (str) => (str[0] === '0' ? str.substring(1) : str)

export const formatDateShort = (dateString) => {
  const parts = dateString.split('-')

  const month = removePrecedingZero(parts[1])
  const day = removePrecedingZero(parts[2])

  return `${month}/${day}`
}

export const getISODateOnly = (date) => {
  return new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timezone: 'UTC',
  })
    .format(date)
    .split('/')
    .reverse()
    .join('-') // returns date in format "2024-12-24"
}

export const getDay = (date) => date.toLocaleDateString('en-us', { weekday: 'long' })

export const processDates = (data, pluralize = false) => {
  const dates = pluralize ? data.map((d) => d + 's') : data
  const allDaysExceptLast = dates.slice(0, -1).join(', ')
  const lastDay = dates.slice(-1)[0]
  const isSingleDay = dates.length < 2
  const daysDelimiter = isSingleDay ? '' : ' and '

  return [allDaysExceptLast, lastDay].join(daysDelimiter)
}
