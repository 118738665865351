import { gql } from '@apollo/client'

const RefreshToken = gql`
  mutation RefreshToken($input: CreateSessionInput!) {
    createSession(input: $input) {
      jwt
      refreshToken
      errors
    }
  }
`

export const Queries = {
  RefreshToken,
}
