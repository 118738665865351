import React, { createContext, useContext, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { bakerIdVar } from 'web/App'
import { BannerProvider } from 'web/contexts/AnnouncementBannerContext'
import { FetchError } from 'shared/components/FetchError'
import { Spinner } from 'shared/components/Spinner'
import { ThemeProvider } from 'web/contexts/ThemeContext'
import { useBakesyQuery } from 'shared/hooks/useBakesyQuery'

const OrderContext = createContext()

export const OrderProvider = ({ children, query }) => {
  const { orderNumber, orderId } = useParams()

  const {
    data: order,
    loading,
    error,
    refetch,
  } = useBakesyQuery(query, {
    fetchPolicy: 'cache-first',
    variables: { id: orderId, orderNumber },
    transform: (data) => data?.order,
  })

  const bakery = useMemo(() => order?.bakery ?? {}, [order])

  useEffect(() => {
    bakerIdVar(bakery?.user?.id)
  }, [bakery?.user?.id])

  if (loading) return <Spinner page />
  if (error) return <FetchError page onRetry={refetch} message={error.message} />

  return (
    <OrderContext.Provider
      value={{
        bakery,
        error,
        loading,
        order,
        refetch,
      }}
    >
      <ThemeProvider
        buttonPreferencesId={bakery.buttonPreferences?.id}
        premiumThemeId={bakery.premiumTheme?.id}
        storefrontAppearanceId={bakery.storefrontAppearance?.id}
      >
        <BannerProvider
          bannerId={bakery.announcementBanner?.id}
          enableAnnouncementBanner={bakery.enableAnnouncementBanner}
          themeId={bakery.premiumTheme?.id}
        >
          {children}
        </BannerProvider>
      </ThemeProvider>
    </OrderContext.Provider>
  )
}

export const useOrderContext = () => useContext(OrderContext)
