import { useMemo } from 'react'

/**
 *
 * @param {[Offering]} offerings - offerings to categorize; preferably should have a category.position field
 * @returns object with category IDs as keys and offerings arrays as values, i.e. `{categoryId: [{offering}, {offering}]}`
 */

export const useOfferingsByCategory = (offerings) => {
  return useMemo(() => {
    const categorizedOfferings = {}

    for (const offering of offerings) {
      const position = String(offering.category?.position || 9999).padStart(3, '0')
      const key = `category-${position}`

      if (!categorizedOfferings[key]) categorizedOfferings[key] = []
      categorizedOfferings[key].push(offering)
    }
    return categorizedOfferings
  }, [offerings])
}
