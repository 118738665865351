import React, { createContext, useContext, useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useFragment } from '@apollo/client'

import { AnnouncementBannerFragment } from 'shared/graphql/fragments'
import { useThemeContext } from './ThemeContext'
import { useWindowDimensions } from 'web/components/WindowDimensionsProvider'

const BannerContext = createContext()

export const BannerProvider = ({ bannerId, children, enableAnnouncementBanner }) => {
  const { data } = useFragment({
    fragment: AnnouncementBannerFragment,
    fragmentName: 'AnnouncementBanner',
    from: bannerId
      ? {
          __typename: 'AnnouncementBanner',
          id: bannerId,
        }
      : null,
  })

  const { setDimensions, bannerHeight } = useWindowDimensions()
  const { secondaryFont } = useThemeContext()

  const [hidden, setHidden] = useState(false)
  const handleHide = () => setHidden(true)

  const compRef = useRef()

  useLayoutEffect(() => {
    if (compRef.current) {
      setDimensions((ps) => ({
        ...ps,
        bannerHeight: compRef.current.clientHeight,
      }))
    } else if (bannerHeight) setDimensions((ps) => ({ ...ps, bannerHeight: 0 }))
  }, [setDimensions, data.message, bannerHeight])

  const hasBanner = enableAnnouncementBanner && !hidden

  if (window.location.pathname.includes('order-request'))
    return <BannerContext.Provider value={{ hasBanner }}>{children}</BannerContext.Provider>

  return (
    <BannerContext.Provider value={{ hasBanner }}>
      {enableAnnouncementBanner && !hidden && (
        <BannerContainer
          backgroundColor={data?.backgroundColor}
          fontFamily={secondaryFont}
          ref={compRef}
        >
          <BannerText $color={data?.fontColor}>{data?.message}</BannerText>

          <CloseButton onClick={handleHide}>&times;</CloseButton>
        </BannerContainer>
      )}
      {children}
    </BannerContext.Provider>
  )
}

export const useBannerContext = () => useContext(BannerContext)

const BannerContainer = styled.div(({ backgroundColor, fontFamily }) => ({
  backgroundColor,
  padding: '10px 0 10px 10px !important',
  display: 'flex',
  flexDirection: 'row',
  fontFamily,
}))

const BannerText = styled.div(({ $color }) => ({
  color: $color,
  width: '100%',
  whiteSpace: 'pre-wrap',
}))

const CloseButton = styled.div({
  color: 'black !important',
  padding: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 24,
  width: 30,
  height: 30,
  cursor: 'pointer',
})
