import React, { memo } from 'react'
import styled from 'styled-components'
import { faCheck, faPlus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFragment } from '@apollo/client'

import { Colors } from 'shared/styles/Colors'
import { OfferingDetailsFragment } from 'web/graphql/fragments'
import { useThemeContext } from 'web/contexts/ThemeContext'

export const Offering = memo(({ offeringId, selected, storefrontId, onClick }) => {
  const { backgroundColor } = useThemeContext()

  const { data: offering } = useFragment({
    fragment: OfferingDetailsFragment,
    fragmentName: 'OfferingDetails',
    from: {
      __typename: 'Offering',
      id: offeringId,
    },
  })

  return (
    <OfferingContainer
      key={offering.id}
      selected={selected}
      selectedColor={backgroundColor ?? Colors.offeringSelectedBgColor}
      onClick={() => onClick(offering.id)}
    >
      <StyledIcon icon={selected ? faCheck : faPlus} selected={selected} />
      {offering.name}
    </OfferingContainer>
  )
})

const OfferingContainer = styled.div(({ selected, selectedColor }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 10,
  border: `1px solid ${selected ? Colors.offeringSelectedBgColor : Colors.grey25}`,
  backgroundColor: selected ? selectedColor : Colors.white,
  color: selected ? Colors.offeringSelectedTextColor : Colors.grey100,
  padding: '5px 20px',
  marginLeft: 10,
  marginRight: 10,
  marginBottom: 20,
  cursor: 'pointer',
}))

export const StyledIcon = styled(FontAwesomeIcon)(({ selected }) => ({
  marginRight: 10,
  color: selected ? Colors.offeringSelectedTextColor : Colors.grey100,
}))
