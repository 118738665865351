import { apolloClient } from 'shared/services/graphqlClient'
import { CreateSessionDocument } from 'shared/graphql/mutations'
import { initializeUi } from './ui'
import { login, setBootstrapped, setJwt, setRefreshToken } from './account'
import { PlatformStorage } from 'shared/services/PlatformStorage'
import { Queries } from 'shared/constants/Queries'
import { StorageKeys } from 'shared/constants/constants'

export const bootstrap = () => {
  return async (dispatch, getState) => {
    try {
      await dispatch(initializeUi())
      const refreshToken = await PlatformStorage.getItem(StorageKeys.refreshTokenStorageKey)

      if (refreshToken) {
        await dispatch(setRefreshToken(refreshToken))

        const { data } = await apolloClient.mutate({
          mutation: CreateSessionDocument,
          variables: { input: { refreshToken } },
        })

        if (data?.createSession?.user) {
          await dispatch(login({ user: data.createSession.user, jwt: data.createSession.jwt }))
        } else {
          await PlatformStorage.removeItem(StorageKeys.refreshTokenStorageKey)
          console.log('refresh token invalid, clearing')
        }
      } else {
        console.log('no refresh token stored')
      }
    } catch (e) {
      console.log('error bootstrapping', e)
    }

    dispatch(setBootstrapped())
  }
}

export const refreshJwt = () => {
  return async (dispatch, getState) => {
    const { data } = await apolloClient.mutate({
      mutation: Queries.RefreshToken,
      variables: { input: { refreshToken: getState().account.refreshToken } },
    })
    dispatch(setJwt(data.createSession.jwt))
    return data.createSession.jwt
  }
}
