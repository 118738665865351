import React from 'react'

import { ReviewComponent } from './ReviewComponent'
import { useBakeryContext } from 'web/contexts/BakeryContext'

export const GenericReview = () => {
  const { bakery, refetch } = useBakeryContext()

  return <ReviewComponent bakery={bakery} refetch={refetch} />
}
