import React, { memo } from 'react'
import styled from 'styled-components'
import { faFacebook, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons'
import { useFragment } from '@apollo/client'

import { Colors } from 'shared/styles/Colors'
import { FixedWidthContainer } from 'web/styles/responsive'
import { Icon } from './Icon/Icon'
import { PremiumThemeFragment, StorefrontAppearanceFragment } from 'web/graphql/fragments'
import { useBakeryContext } from 'web/contexts/BakeryContext'

const LinkButton = memo(({ bgColor, type, handle, children }) => {
  const href = handle.includes('http')
    ? handle
    : `https://www.${type}.com/${type === 'tiktok' && handle[0] !== '@' ? '@' : ''}${handle}`

  return (
    <ButtonWrapper backgroundColor={bgColor} href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </ButtonWrapper>
  )
})

export const SocialButtons = memo(() => {
  const { bakery } = useBakeryContext()

  const { data: premiumTheme } = useFragment({
    fragment: PremiumThemeFragment,
    fragmentName: 'PremiumTheme',
    from: {
      __typename: 'PremiumTheme',
      id: bakery.premiumTheme?.id,
    },
  })

  const { data: storefrontAppearance } = useFragment({
    fragment: StorefrontAppearanceFragment,
    fragmentName: 'StorefrontAppearance',
    from: {
      __typename: 'StorefrontAppearance',
      id: bakery.storefrontAppearance?.id,
    },
  })

  const { facebookHandle, instagramHandle, tiktokHandle, primaryColor } = {
    ...premiumTheme,
    ...storefrontAppearance,
  }

  return (
    <Container backgroundColor={primaryColor ?? Colors.defaultPrimary}>
      {facebookHandle || instagramHandle || tiktokHandle ? (
        <>
          <Notice>Follow us!</Notice>
          <ButtonsWrapper>
            {facebookHandle && (
              <LinkButton type="facebook" bgColor={Colors.white} handle={facebookHandle}>
                <Icon icon={faFacebook} size={30} />
              </LinkButton>
            )}
            {instagramHandle && (
              <LinkButton type="instagram" handle={instagramHandle}>
                <Icon icon={faInstagram} size={20} color={Colors.white} />
              </LinkButton>
            )}
            {tiktokHandle && (
              <LinkButton type="tiktok" handle={tiktokHandle}>
                <Icon icon={faTiktok} size={20} color={Colors.white} />
              </LinkButton>
            )}
          </ButtonsWrapper>
        </>
      ) : (
        <Notice>{bakery.name}</Notice>
      )}
    </Container>
  )
})

const Container = styled(FixedWidthContainer)(({ backgroundColor }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: 20,
  gap: 20,
  backgroundColor,
  width: '100%',
}))

const Notice = styled.p({
  margin: 0,
})

const ButtonsWrapper = styled.div({
  display: 'flex',
  gap: 20,
})

const ButtonWrapper = styled.a(({ backgroundColor = Colors.black }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 30,
  height: 30,
  backgroundColor,
  borderRadius: 100,
}))
