import React from 'react'
import styled from 'styled-components'

export const MultiLineText = ({ Container, children = '' }) => (
  <Container>
    {children.split('\n').map((line) => (
      <TextLine key={line + `_${Math.random()}`}>{line}</TextLine>
    ))}
  </Container>
)

const TextLine = styled.p({
  wordBreak: 'break-word',
})
