import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'

import { Configuration } from 'shared/services/Configuration'
import { CreateUserDocument } from 'shared/graphql/mutations'
import { login } from 'shared/reducers/account'
import { mapGraphqlErrors } from 'shared/utils/mapGraphqlErrors'
import { useEffectOnce } from 'shared/hooks/useEffectOnce'
import { UserSchema } from 'native/validators/UserSchema'

export const useAccountCreation = ({ serviceData = {}, afterCreate } = {}) => {
  const dispatch = useDispatch()

  const [createUser] = useMutation(CreateUserDocument)

  const onSubmit = async (data) => {
    try {
      if (!data.provider) delete data.provider
      if (!data.token) delete data.token

      /* NOTE: this needs to be 'var' so these aren't scoped to the try block */
      var {
        data: {
          createUser: { user, jwt, refreshToken, errors },
        },
      } = await createUser({
        variables: {
          input: { ...data, createdInApp: Configuration.isNative },
        },
      })

      if (errors.length) {
        const userErrors = mapGraphqlErrors(errors).user
        return userErrors.forEach(([field, message]) => accountForm.setError(field, { message }))
      } else {
        dispatch(login({ user, jwt, refreshToken }))
        afterCreate?.(user)
      }
    } catch (e) {
      accountForm.setError('form', 'Uknown error creating account')
    }
  }

  const accountForm = useForm({
    defaultValues: {
      name: '',
      email: '',
      password: '',
      provider: '',
      token: '',
    },
    mode: 'blur',
    resolver: yupResolver(UserSchema),
  })

  useEffectOnce(() => {
    accountForm.setValue('name', serviceData.name)
    accountForm.setValue('email', serviceData.email)
    accountForm.setValue('provider', serviceData.provider)
    accountForm.setValue('token', serviceData.token)
    accountForm.setValue('tokenType', serviceData.tokenType)
  }, !!Object.keys(serviceData).length)

  return { ...accountForm, handleSubmit: accountForm.handleSubmit(onSubmit), createUser }
}
