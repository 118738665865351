import React, { createContext, useContext, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { bakerIdVar } from 'web/App'
import { BakeryLayoutDocument } from 'web/graphql/queries/Bakery'
import { BannerProvider } from 'web/contexts/AnnouncementBannerContext'
import { FetchError } from 'shared/components/FetchError'
import { isEmptyShallow } from 'shared/utils/isEmpty'
import { Spinner } from 'shared/components/Spinner'
import { ThemeProvider } from 'web/contexts/ThemeContext'
import { useBakesyQuery } from 'shared/hooks/useBakesyQuery'
import { useOrderContext } from './OrderContext'

export const BakeryContext = createContext()

export const BakeryProvider = ({ children, query = BakeryLayoutDocument }) => {
  const { slug, productSlug } = useParams()
  const orderContext = useOrderContext()

  const {
    data: queryData,
    loading,
    error,
    refetch,
  } = useBakesyQuery(query, {
    fetchPolicy: 'cache-first',
    transform: (data) => data?.bakery,
    skip: !slug,
    variables: {
      slug,
      bakerySlug: slug,
      productSlug,
      visit: true,
    },
  })

  const bakery = useMemo(
    () => queryData || orderContext?.order?.bakery,
    [orderContext?.order?.bakery, queryData],
  )

  useEffect(() => {
    bakerIdVar(bakery?.user?.id)
  }, [bakery?.user?.id])

  if (loading || !bakery) return <Spinner page />
  if (error) return <FetchError page onRetry={refetch} message={error.message} />
  if (!isEmptyShallow(orderContext))
    return <BakeryContext.Provider value={{ bakery, refetch }}>{children}</BakeryContext.Provider>

  return (
    <BakeryContext.Provider value={{ bakery, refetch }}>
      <ThemeProvider
        buttonPreferencesId={bakery.buttonPreferences?.id}
        premiumThemeId={bakery.premiumTheme?.id}
        storefrontAppearanceId={bakery.storefrontAppearance?.id}
      >
        <BannerProvider
          bannerId={bakery.announcementBanner?.id}
          enableAnnouncementBanner={bakery.enableAnnouncementBanner}
        >
          {children}
        </BannerProvider>
      </ThemeProvider>
    </BakeryContext.Provider>
  )
}

export const useBakeryContext = () => useContext(BakeryContext)

export default BakeryContext
