import React, { useState } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'

import { defaultLogo } from 'web/pages/OrderRequest/constants'
import { Header } from 'web/pages/OrderRequest/styles'
import { isEmptyShallow } from 'shared/utils/isEmpty'
import { ReviewFormComponent } from './ReviewForm'
import { ReviewItem } from '../Reviews'
import { useEffectOnce } from 'shared/hooks/useEffectOnce'

export const ReviewComponent = ({ bakery, refetch, order = {} }) => {
  const navigate = useNavigate()
  const { review, customer } = order

  const [showNotice, setShowNotice] = useState(false)

  useEffectOnce(() => {
    if (bakery.status !== 'active') navigate('/')
  }, !!bakery)

  const name = customer?.name.split(' ')[0]
  const logo = bakery.user.avatar?.smallUrl || defaultLogo
  const title = `Leave a Rating/Review ⭐️⭐️⭐️⭐️⭐️ | ${bakery.name}`

  const handleSuccess = () => {
    if (!isEmptyShallow(order)) {
      setShowNotice(true)
    } else navigate(`/b/${bakery.slug}/reviews`)

    refetch()
  }

  if (review)
    return (
      <Container>
        {showNotice && <Notice>Thank you for leaving a review!</Notice>}
        <ReviewItem reviewId={review.id} />
      </Container>
    )

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta name="description" content="" />
        <meta property="og:description" content="" />
        <meta property="og:image" content={logo} />
      </Helmet>

      <ReviewFormComponent
        bakeryId={bakery.id}
        customerName={name}
        onSuccess={handleSuccess}
        orderNumber={order?.orderNumber}
      />
    </>
  )
}

const Container = styled.div({
  padding: '5%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100vh',
})

const Notice = styled(Header)({
  fontSize: 40,
  marginBottom: 80,

  '@media (max-width: 767px)': {
    fontSize: '3rem',
    marginBottom: 40,
  },
})
