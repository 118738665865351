import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { useFragment } from '@apollo/client'

import { CardBackground } from 'web/styles/shared'
import { Collapsible } from 'shared/components/Collapsible'
import { defaultLogo } from 'web/pages/OrderRequest/constants'
import { FaqFragment, PremiumThemeFragment } from 'web/graphql/fragments'
import { FixedWidthContainer } from 'web/styles/responsive'
import { MainSubtitle } from './Details'
import { useBakeryContext } from 'web/contexts/BakeryContext'
import { useResponsiveLayout } from 'web/hooks/useResponsiveLayout'
import { useWindowDimensions } from 'web/components/WindowDimensionsProvider'

const FaqComponent = ({ faqId }) => {
  const { data } = useFragment({
    fragment: FaqFragment,
    fragmentName: 'Faq',
    from: {
      __typename: 'Faq',
      id: faqId,
    },
  })

  return (
    <Collapsible Header={data.title} key={data.title}>
      {data.description}
    </Collapsible>
  )
}

export const FAQ = () => {
  const { isMdUp } = useResponsiveLayout()
  const { bakery } = useBakeryContext()
  const { layoutHeight } = useWindowDimensions()

  const {
    data: { primaryFont },
  } = useFragment({
    fragment: PremiumThemeFragment,
    fragmentName: 'PremiumTheme',
    from: {
      __typename: 'PremiumTheme',
      id: bakery.premiumTheme?.id,
    },
  })

  const title = `FAQ | ${bakery.name}`
  const avatar = bakery?.user?.avatar
  const logo = avatar?.largeUrl || avatar?.smallUrl || defaultLogo

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:image" content={logo} />
      </Helmet>

      <Container minHeight={layoutHeight}>
        <MainColumn isMdUp={isMdUp}>
          <Card>
            <Heading font={primaryFont}>Frequently Asked Questions</Heading>

            {bakery.faqs.map((faq) => (
              <FaqComponent key={faq.id} faqId={faq.id} />
            ))}
          </Card>
        </MainColumn>
      </Container>
    </>
  )
}
const Container = styled(FixedWidthContainer)(
  ({ minHeight }) => `
    margin-top: 40px;
    margin-bottom: 40px;
    min-height: ${minHeight - 75}px;
  `,
)

const Card = styled.div({
  ...CardBackground,
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 30px',
  marginBottom: 20,
})

const Heading = styled(MainSubtitle)`
  display: flex;
  margin-top: 20px;
  margin-bottom: 40px;
  align-self: center;
  text-align: center;
`

const MainColumn = styled.div`
  margin-top: 30px;
  flex: 1;
`
