import AriaModal from 'react-aria-modal'
import React, { memo, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { addDays, addMonths, format, formatISO } from 'date-fns'
import { Calendar } from 'react-calendar'
import { useFragment } from '@apollo/client'

import { AvailabilityPreferencesFragment, OfferingFragment } from 'web/graphql/fragments'
import { Button } from './Button'
import { Colors } from 'shared/styles/Colors'
import { formatDateShort, processDates } from 'shared/utils/dates'
import { getContrastColor } from 'shared/utils/getContrastColor'
import { MediaQueries } from 'web/styles/responsive'
import { useResponsiveLayout } from 'web/hooks/useResponsiveLayout'
import { useThemeContext } from 'web/contexts/ThemeContext'

const dateNow = new Date()
const initialModalState = {
  title: 'This date is blocked',
  text: '',
  unavailableOfferings: [],
  show: false,
}

const AvailabilityTextComponent = memo(({ slug }) => {
  const { data: offering } = useFragment({
    from: { __typename: 'Offering', slug },
    fragment: OfferingFragment,
  })

  const { data: availability } = useFragment({
    from: { __typename: 'AvailabilityPreferences', offeringSlug: slug },
    fragment: AvailabilityPreferencesFragment,
  })

  const isDate = availability.type === 'date'
  const preference = isDate
    ? availability.preference.map((p) => formatDateShort(p))
    : availability.preference

  return (
    <ModalText>
      {offering.name} (Only available {isDate ? '' : 'on '}
      {processDates(isDate ? preference.sort() : preference, !isDate)})
    </ModalText>
  )
})

export const CalendarComponent = ({
  value = dateNow,
  bakery,
  onChange = undefined,
  modalStyle = {},
  offeringsAvailability = {},
  isDateUnavailable = () => false,
}) => {
  const { isMdUp } = useResponsiveLayout()
  const { primaryColor, secondaryFont } = useThemeContext()

  const [modalState, setModalStateRaw] = useState({ ...initialModalState })

  const blockedLeadTimeDaysCount = useMemo(
    () => (bakery.leadTimeUnit === 'weeks' ? bakery.leadTimeValue * 7 : bakery.leadTimeValue),
    [bakery],
  )

  const leadTimeBlockedDays = useMemo(
    () =>
      Array.from({ length: blockedLeadTimeDaysCount + 1 }, (_, i) =>
        formatISO(addDays(dateNow, i), { representation: 'date' }),
      ),
    [blockedLeadTimeDaysCount],
  )

  const blockedDates = useMemo(
    () => bakery.blockedDates.concat(bakery.leadTimeBlockEnabled ? leadTimeBlockedDays : []),
    [bakery, leadTimeBlockedDays],
  )

  const isLeadTimeBlocked = useCallback(
    (date) => blockedDates.includes(format(date, 'yyyy-MM-dd')),
    [blockedDates],
  )

  const isFutureBlocked = useCallback(
    (date) => bakery.futureBlockMonths && date > addMonths(dateNow, bakery.futureBlockMonths),
    [bakery],
  )

  const isDateBlocked = useCallback(
    (date) => {
      return isLeadTimeBlocked(date) || isFutureBlocked(date) || isDateUnavailable(date)
    },
    [isDateUnavailable, isFutureBlocked, isLeadTimeBlocked],
  )

  const setModalState = (data) => setModalStateRaw((ps) => ({ ...ps, ...data, show: true }))

  const handleClickDay = (date) => {
    if (isLeadTimeBlocked(date)) {
      setModalState({
        text: `${bakery.name} is not accepting orders for this date.`,
      })
    } else if (isFutureBlocked(date)) {
      setModalState({
        text: `${bakery.name} does not accept order requests this far in advance. Please contact them directly.`,
      })
    } else if (isDateUnavailable(date)) {
      setModalState({
        title: 'Unable to place order on this date due to the following item(s):',
        unavailableOfferings: Object.keys(offeringsAvailability.byOrderItem),
      })
    }
  }

  const selectedTextColor = useMemo(() => {
    if (primaryColor !== Colors.defaultPrimary) return getContrastColor(primaryColor)
  }, [primaryColor])

  return (
    <>
      <CalendarContainer
        modalStyle={modalStyle}
        selectedColor={primaryColor}
        selectedTextColor={selectedTextColor}
        fontFamily={secondaryFont}
      >
        <Calendar
          value={value}
          onChange={(date) => !!onChange && !isDateBlocked(date) && onChange(date)}
          onClickDay={handleClickDay}
          calendarType="gregory"
          minDetail="month"
          next2Label={null}
          prev2Label={null}
          minDate={dateNow}
          showNeighboringMonth={false}
          showDoubleView={isMdUp}
          tileClassName={({ date }) => isDateBlocked(date) && 'blocked'}
        />
      </CalendarContainer>
      {modalState.show && (
        <AriaModal
          titleText={modalState.title}
          underlayStyle={{ paddingTop: '40px', paddingBottom: '40px' }}
          underlayClickExits={false}
          escapeExits={false}
          verticallyCenter
          focusDialog={true}
        >
          <Modal>
            <ModalTitle>{modalState.title}</ModalTitle>
            <ModalText>{modalState.text}</ModalText>
            {modalState.unavailableOfferings.map((slug) => (
              <AvailabilityTextComponent key={slug} slug={slug} />
            ))}
            <ModalClose onClick={() => setModalStateRaw({ ...initialModalState })}>
              Close
            </ModalClose>
          </Modal>
        </AriaModal>
      )}
    </>
  )
}

export const CalendarContainer = styled.div(
  ({ modalStyle, selectedColor, fontFamily, selectedTextColor }) => ({
    display: 'flex',
    justifyContent: 'center',

    '.react-calendar': {
      border: 0,
      backgroundColor: 'transparent',
      fontFamily,
      ...modalStyle,
    },
    '.react-calendar__navigation__label': {
      color: Colors.grey100,
    },
    '.react-calendar__navigation button[disabled]': {
      backgroundColor: 'transparent',
    },
    '.react-calendar__month-view__days__day--neighboringMonth': {
      opacity: 0,
    },
    '.react-calendar__tile': {
      color: Colors.grey100,
      borderRadius: 100,
    },
    '.react-calendar__month-view__days__day': {
      color: Colors.black,
      fontWeight: 'bold',
    },
    '.blocked:hover, .blocked:focus': {
      backgroundColor: 'transparent',
    },
    '.react-calendar__tile:disabled, .blocked': {
      backgroundColor: 'transparent',
      color: Colors.grey50,
      fontWeight: 'lighter',
    },
    '.react-calendar__tile--active:hover, .react-calendar__tile--active:focus': {
      backgroundColor: selectedColor,
    },
    '.react-calendar__tile--now': {
      fontWeight: 'bold',
      background: 'none',
    },
    '.react-calendar__tile--now:hover': {
      background: '#e6e6e6',
    },
    '.react-calendar__tile--active.react-calendar__tile--now': {
      backgroundColor: selectedColor,
      color: selectedTextColor,
    },
    '.react-calendar__tile--active': {
      backgroundColor: selectedColor,
      color: selectedTextColor,
    },
  }),
)

const Modal = styled.div({
  backgroundColor: Colors.white,
  padding: 20,
  borderRadius: 4,
  boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  width: 'calc(100% - 40px)',
  position: 'relative',
  margin: '0 20px',
  display: 'flex',
  flexDirection: 'column',

  [`@media ${MediaQueries.mdUp}`]: {
    width: 450,
    margin: '0 auto',
  },
})

const modalCommons = {
  margin: '0 0 5px',
}

const ModalTitle = styled.h3({
  ...modalCommons,
  fontSize: '1.05em',
})

const ModalText = styled.p({
  ...modalCommons,
  fontSize: '0.9em',
})

export const ModalClose = styled(Button)({
  alignSelf: 'flex-end',
  padding: '10px 10px 0',
  color: Colors.brand,
})
