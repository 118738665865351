import React, { memo } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Colors } from 'shared/styles/Colors'
import { Divider, Row, Strong } from 'shared/components/Invoice/styles'
import { useInvoiceContext } from 'shared/contexts/InvoiceContext'

const contactMethods = {
  call: { name: 'Phone', method: 'tel:' },
  email: { name: 'Email', method: 'mailto:' },
  sms: { name: 'SMS', method: 'sms:' },
}

export const BakerySection = memo(({ show, Container = React.Fragment }) => {
  const { order } = useInvoiceContext()
  const { slug, name, user } = order.bakery

  const renderMethod = (isHref = false) => {
    if (user.contactMethod === 'email') return user.email

    return isHref ? user.mobileNumber : user.formattedMobileNumber
  }

  if (!show) return null

  return (
    <Container>
      <Row>
        <Bakery to={`/b/${slug}`}>{name}</Bakery>
      </Row>

      <Row>
        <Strong>{contactMethods[user.contactMethod].name}</Strong>
        <Email href={`${contactMethods[user.contactMethod].method}${renderMethod(true)}`}>
          {renderMethod()}
        </Email>
      </Row>

      <Divider />
    </Container>
  )
})

const Bakery = styled(Link)({
  textDecoration: 'underline',
  fontWeight: 'bold',
  marginRight: 4,
  fontSize: 16,
})

const Email = styled.a({
  marginLeft: 10,
  color: Colors.blue,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})
