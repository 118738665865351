import styled from 'styled-components'

export const Breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
}

export const MediaQueries = {
  smUp: `only screen and (min-width: ${Breakpoints.sm}px)`,
  smDn: `only screen and (max-width: ${Breakpoints.sm}px)`,
  mdUp: `only screen and (min-width: ${Breakpoints.md}px)`,
  lgUp: `only screen and (min-width: ${Breakpoints.lg}px)`,
}

export const FixedWidthContainer = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;

  @media ${MediaQueries.smUp} {
    width: ${Breakpoints.sm - 22}px;
  }
  @media ${MediaQueries.mdUp} {
    width: ${Breakpoints.md - 22}px;
  }
  @media ${MediaQueries.lgUp} {
    width: ${Breakpoints.lg - 22}px;
  }
`
