import React, { useMemo } from 'react'
import styled from 'styled-components'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFragment } from '@apollo/client'

import { bakeryPath } from 'shared/utils/bakeries'
import { CategoryMenuLink, MenuLink } from './CategoryMenuLink'
import { Colors } from 'shared/styles/Colors'
import { toRawMobileNumber } from 'shared/utils/phone'
import { useBakeryContext } from 'web/contexts/BakeryContext'
import { UserFragment } from 'web/graphql/fragments'
import { useThemeContext } from 'web/contexts/ThemeContext'
import { useWindowDimensions } from 'web/components/WindowDimensionsProvider'

export const Sidebar = ({ Avatar, isVisible, onClick, isMdUp }) => {
  const { height } = useWindowDimensions()
  const { bakery } = useBakeryContext()
  const { primaryColor } = useThemeContext()

  const { data: user } = useFragment({
    fragment: UserFragment,
    fragmentName: 'User',
    from: {
      __typename: 'User',
      id: bakery.user.id,
    },
  })

  const contactLink = useMemo(() => {
    switch (user.contactMethod) {
      case 'email':
        return `mailto:${user.email}`
      case 'call':
        return `tel:+${toRawMobileNumber(user.formattedMobileNumber)}`
      case 'sms':
        return `sms:+${toRawMobileNumber(user.formattedMobileNumber)}`
      default:
        return null
    }
  }, [user])

  return (
    <SidebarContainer $height={height} $isVisible={isVisible} $isMdUp={isMdUp}>
      <Close onClick={onClick}>
        <FontAwesomeIcon icon={faTimes} color={Colors.black} size="lg" />
      </Close>
      <AvatarContainer onClick={onClick}>{Avatar}</AvatarContainer>

      <SidebarContent onClick={onClick}>
        {bakery.categories.map((category) => (
          <CategoryMenuLink slug={category.slug} key={category.slug} />
        ))}

        {!!bakery.galleryImage && (
          <MenuLink $primaryColor={primaryColor} to={`${bakeryPath(bakery)}/gallery`}>
            Gallery
          </MenuLink>
        )}

        {!!bakery.faqs.length && (
          <MenuLink $primaryColor={primaryColor} to={`${bakeryPath(bakery)}/faqs`}>
            FAQs
          </MenuLink>
        )}

        {!!bakery.reviewSummary.count && (
          <MenuLink $primaryColor={primaryColor} to={`${bakeryPath(bakery)}/reviews`}>
            Reviews
          </MenuLink>
        )}

        <Contact $primaryColor={primaryColor} href={contactLink}>
          Contact
        </Contact>
      </SidebarContent>
    </SidebarContainer>
  )
}

const SidebarContainer = styled.div(
  ({ $isVisible, $isMdUp, $height }) => `
    width: ${$isMdUp ? '25%' : '50%'};
    position: fixed;
    z-index: 120;
    right: 0;
    top: 0;
    background: ${Colors.white} ;
    height: ${$height}px;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
    right: ${$isVisible ? '0' : '-100%'};
    transition: 350ms;
    padding-top: 20px;
  `,
)

const SidebarContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  text-align: center;
  overflow: auto;
  pointer-events: auto;
  z-index: 1000;
  & a {
    color: ${Colors.black};
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    padding: 10px 0;
  }
`

const AvatarContainer = styled.div`
  width: 100%;
  padding: 20px 0px;
  justify-content: center;
  border-bottom: 1px solid ${Colors.grey25};
  margin-bottom: 30px;
`

const Close = styled.div`
  position: absolute;
  top: 10px;
  left 20px;
`

const Contact = styled.a(
  ({ $primaryColor }) => `
    text-align: center;
    :hover {
      color: ${$primaryColor};
    }
  `,
)
