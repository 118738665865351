import React, { useMemo } from 'react'

import { Helper, Row, Strong } from 'shared/components/Invoice/styles'
import { MultiLineText } from 'web/components/form'
import { useInvoiceContext } from 'shared/contexts/InvoiceContext'

export const NoteSection = ({ show, Container = React.Fragment }) => {
  const { order } = useInvoiceContext()

  const note = useMemo(() => order.bakerOrderDetails, [order])

  if (!show || !note) return null

  return (
    <Container>
      <Row $marginBottom={15}>
        <Strong>Order Notes</Strong>
      </Row>

      <Row>
        <MultiLineText Container={Helper}>{note}</MultiLineText>
      </Row>
    </Container>
  )
}
