import pluralize from 'pluralize'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { faCheck } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSearchParams } from 'react-router-dom'

import { Colors } from 'shared/styles/Colors'
import { Header, HelpText } from 'web/pages/OrderRequest/styles'
import { OrderRequestLayout } from 'web/pages/OrderRequest/OrderRequestLayout'
import { StyledTextArea } from 'web/components/form'
import { useOrderBuild } from 'web/hooks/useOrderBuild'
import { useOrderContext } from 'web/contexts/OrderContext'
import { useThemeContext } from 'web/contexts/ThemeContext'
import { useUpdateOrder } from 'shared/hooks/useUpdateOrder'

export const Questionnaire = ({ bakerySlug }) => {
  const { order } = useOrderContext()
  const { backgroundColor } = useThemeContext()
  const [searchParams] = useSearchParams()
  const isBack = searchParams.get('back')

  const { loading, updateOrder } = useUpdateOrder({ order })
  const { customQuestions } = useOrderBuild({ order })

  const [answer, setAnswer] = useState('')
  const [questionIndex, setQuestionIndex] = useState(isBack ? customQuestions.length - 1 : 0)

  const currentQuestion = customQuestions[questionIndex]

  const questionCategoriesIds = useMemo(() => {
    const categories = currentQuestion?.categories || []

    return [...new Set(categories.map((category) => category.id))]
  }, [currentQuestion])

  const questionOfferingsIds = useMemo(() => {
    const offerings = currentQuestion?.offerings || []

    return [...new Set(offerings.map((offering) => offering.id))]
  }, [currentQuestion])

  const questionItem = useMemo(() => {
    const items = [
      ...new Set(
        order?.orderItems.filter((oi) => {
          return (
            questionCategoriesIds.includes(oi.categoryId) ||
            questionOfferingsIds.includes(oi.offeringId)
          )
        }),
      ),
    ]

    const currentQuestionOrderItemIndex = currentQuestion?.orderItemIdx

    if (currentQuestionOrderItemIndex == null) return null

    return items[currentQuestionOrderItemIndex]
  }, [currentQuestion, order, questionCategoriesIds, questionOfferingsIds])

  const { question, required, choices, multiSelect, id, applySeparately } = currentQuestion || {}

  const helpItemsText = useCallback(
    (namesOnly = false) => {
      if (!questionItem) return ''

      let itemNames
      let itemsLength = 1

      if (applySeparately) itemNames = questionItem.offering.name
      else {
        const questionOrderItems = [
          ...new Set(
            order?.orderItems.filter((oi) => {
              return (
                questionCategoriesIds.includes(oi.categoryId) ||
                questionOfferingsIds.includes(oi.offeringId)
              )
            }),
          ),
        ]
        itemsLength = questionOrderItems.length
        itemNames = questionOrderItems.map((oi) => oi.offering.name).join(', ')
      }

      if (!itemNames.length) return ''

      const text = pluralize('Product', (!applySeparately && itemsLength) || 1)
      return namesOnly ? itemNames : `${text}: ${itemNames}`
    },
    [applySeparately, order, questionCategoriesIds, questionOfferingsIds, questionItem],
  )

  const handleBack = () => {
    setQuestionIndex(questionIndex - 1)
    setAnswer('')
  }

  useEffect(() => {
    const previousAnswer = order.customQuestions?.find((orderCustomQuestion) => {
      if (orderCustomQuestion.id && id) {
        if (orderCustomQuestion.forId)
          return (
            orderCustomQuestion.id === id && orderCustomQuestion.forId === questionItem?.offeringId
          )
        else return orderCustomQuestion.id === id
      }
      return orderCustomQuestion.question === question
    })

    if (previousAnswer) {
      setAnswer(previousAnswer.answer || previousAnswer.answers)
    }
  }, [question, choices, order, id, questionItem])

  const onNext = ({ navigateToNextScreen }) => {
    const moreQuestions = questionIndex + 1 < customQuestions.length

    const _question = questionItem ? `${question} (${helpItemsText(true)})` : question
    const customQuestionInput = { question: _question, id }

    if (questionItem) customQuestionInput.forId = questionItem.offeringId
    if (Array.isArray(answer)) customQuestionInput.answers = answer
    else customQuestionInput.answer = answer

    const params = { input: { customQuestion: customQuestionInput } }

    if (answer) updateOrder(params)
    setQuestionIndex(questionIndex + 1)
    setAnswer('')
    if (!moreQuestions) navigateToNextScreen()
  }

  const onMultiSelectChoiceClick = (selected, choice) => {
    if (selected) {
      setAnswer(answer.filter((a) => a !== choice.toString()))
    } else {
      setAnswer([...answer, choice.toString()])
    }
  }

  const onChoiceClick = (selected, choice) => {
    if (selected) {
      setAnswer('')
    } else {
      setAnswer(choice)
    }
  }

  return (
    <OrderRequestLayout
      nextDisabled={(required && !answer.length) || loading}
      onBack={questionIndex === 0 ? null : handleBack}
      onNext={onNext}
      bakerySlug={bakerySlug}
      nextButtonText={required || answer ? 'Next' : 'Skip'}
    >
      <CustomHeader>
        {question} {choices && multiSelect && '(select all that apply)'}
      </CustomHeader>
      <CustomHelpText>{helpItemsText()}</CustomHelpText>

      <OffersContainer>
        {choices ? (
          choices.map((choice, index) => {
            const selected = multiSelect
              ? answer.indexOf(choice.toString()) > -1
              : answer === choice.toString()

            return (
              <Offering
                key={index}
                selected={selected}
                selectedColor={backgroundColor}
                onClick={() => {
                  if (multiSelect) {
                    onMultiSelectChoiceClick(selected, choice)
                  } else {
                    onChoiceClick(selected, choice, index)
                  }
                }}
              >
                {selected && <StyledIcon icon={faCheck} />}
                {choice}
              </Offering>
            )
          })
        ) : (
          <StyledTextArea
            autoFocus={required}
            placeholder={required ? '' : '(optional)'}
            value={answer}
            required={required}
            onChange={(evt) => setAnswer(evt.target.value)}
          />
        )}
      </OffersContainer>
    </OrderRequestLayout>
  )
}

const OffersContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
})

const Offering = styled.div(({ selected, selectedColor }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: 10,
  border: `1px solid ${selected ? Colors.offeringSelectedBgColor : Colors.grey25}`,
  backgroundColor: selected
    ? selectedColor && selectedColor !== Colors.defaultBackground
      ? selectedColor
      : Colors.offeringSelectedBgColor
    : Colors.white,
  color: selected ? Colors.offeringSelectedTextColor : Colors.grey100,
  padding: '5px 20px',
  marginLeft: 10,
  marginRight: 10,
  marginBottom: 20,
  cursor: 'pointer',
}))

const StyledIcon = styled(FontAwesomeIcon)({
  marginRight: 10,
  color: Colors.offeringSelectedTextColor,
})

const CustomHeader = styled(Header)({
  marginBottom: 6,
  wordBreak: 'break-word',
})

const CustomHelpText = styled(HelpText)({
  fontSize: 18,
  textAlign: 'center',
  marginBottom: 12,
})
