import React, { useState } from 'react'
import styled from 'styled-components'

import * as Cross from 'shared/components/Cross'
import { CancelDepositModal } from 'shared/components/CancelDepositModal'
import { Colors } from 'shared/styles/Colors'
import { useInvoiceContext } from 'shared/contexts/InvoiceContext'
import { useResponsiveLayout } from 'web/hooks/useResponsiveLayout'

export const ActionBar = () => {
  const [showModal, setShowModal] = useState(false)
  const [success, setSuccess] = useState(false)

  const { order, payInvoice, stripeLoading, refetch, isPendingConfirmation } = useInvoiceContext()
  const { isMdUp } = useResponsiveLayout()
  const isInstantCheckout = order.isInstantCheckout

  const handleVisible = (visible) => () => setShowModal(visible)

  const handleComplete = () => {
    if (isInstantCheckout) {
      payInvoice()
    } else {
      setShowModal(false)
      refetch()
    }
  }

  const handleAgree = () => {
    if (isInstantCheckout) {
      setShowModal(true)
      setSuccess(true)
    } else {
      payInvoice()
    }
  }

  if (!isPendingConfirmation) return null

  const depositIsZero = order?.depositAmountCents === 0
  const action = isInstantCheckout ? "'Agree to Pay' button below" : "'Accept Invoice'"
  const methods = isInstantCheckout
    ? 'See accepted payment methods below.'
    : 'See payment due date and methods below.'

  return (
    <Container>
      <InstantCheckoutNotice>
        {depositIsZero
          ? `Tap ${action} to confirm your order.`
          : 'Payment required for order confirmation.'}
        &nbsp;
        {depositIsZero ? methods : 'See accepted payment methods below.'}
      </InstantCheckoutNotice>

      <BottomActionButtons $isMdUp={isMdUp}>
        <Cross.Button
          disabled={stripeLoading}
          color={Colors.red}
          onClick={handleVisible(true)}
          button
        >
          Cancel
        </Cross.Button>

        <Cross.Button disabled={stripeLoading} color={Colors.green} onClick={handleAgree} button>
          {order.isInstantCheckout ? 'Agree to Pay' : 'Accept Invoice'}
        </Cross.Button>
      </BottomActionButtons>

      {showModal && (
        <CancelDepositModal
          onDismiss={handleVisible(false)}
          onComplete={handleComplete}
          onFail={handleVisible(false)}
          order={order}
          success={success}
        />
      )}
    </Container>
  )
}

const Container = styled.div({
  height: '100%',
})

const BottomActionButtons = styled.div(({ $isMdUp }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

const InstantCheckoutNotice = styled.p({
  color: Colors.red,
  fontWeight: 600,
  marginTop: 0,
  marginBottom: 30,
})
