import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { FetchError } from 'shared/components/FetchError'
import { ReviewComponent } from './ReviewComponent'
import { useOrderContext } from 'web/contexts/OrderContext'

export const OrderReview = () => {
  const { orderNumber } = useParams()
  const navigate = useNavigate()
  const { order, bakery, refetch } = useOrderContext()

  if (!bakery) return <FetchError page onRetry={refetch} />
  if (order.state !== 'complete') return navigate(`/b/${order.bakery.slug}`)

  return <ReviewComponent bakery={bakery} refetch={refetch} order={{ ...order, orderNumber }} />
}
