import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'

import { useOrderQuestionsBuilder } from 'shared/hooks/useOrderQuestionsBuilder'
import { useQuestionCounter } from 'web/hooks/useQuestionCounter'

export const useOrderBuild = ({ order }) => {
  const location = useLocation()
  const currentPage = location.pathname.split('/').pop()
  const { enableOrderSpecifications, customQuestions: rawQuestions } = order?.bakery || {}
  const { orderItems = [] } = order || {}

  const { skipDueDate, skipInspirationPhotos } = useMemo(() => {
    return {
      skipDueDate: orderItems.every((oi) => oi.offering.dueDateDisabled),
      skipInspirationPhotos: orderItems.every((oi) => !oi.offering.enableInspirationPhotos),
    }
  }, [orderItems])

  const customQuestions = useOrderQuestionsBuilder({
    customQuestions: rawQuestions,
    currentPage,
    orderItems,
  })

  const customQuestionsLength = customQuestions.length
  const dueDateOffset = skipDueDate ? 0 : 1
  const specificationsOffset = enableOrderSpecifications ? 0 : 1
  const inspirationalPhotosOffset = skipInspirationPhotos ? 1 : 0

  const { questionCounter, setQuestionCounter } = useQuestionCounter({
    customQuestionsLength,
    dueDateOffset,
    specificationsOffset,
    currentPage,
    inspirationalPhotosOffset,
  })

  return {
    currentPage,
    customQuestions,
    loading: order?.bakery?.status === 'expired',
    order,
    questionCounter,
    setQuestionCounter,
    skipDueDate,
  }
}
